

import LazyHydrate from 'vue-lazy-hydration'
import { PropType } from 'vue'
import { StrapiSlider } from '@zaprooecommerce/strapi'
import BannerImage from '~/components/molecules/BannerImage.vue'
import { swiperDefaultOptions } from '~/resources/swiper/swiperOptions'
import { useSwiper } from '~/composables/useSwiper'
import BannerLink from '~/components/organisms/Banner/BannerLink.vue'

export default {
  name: 'SingleBanner',
  components: {
    BannerLink,
    BannerImage,
    LazyHydrate
  },
  inheritAttrs: false,
  props: {
    banners: {
      type: Array as PropType<StrapiSlider[]>,
      default: () => null
    },
    sectionIndex: {
      type: Number as PropType<number | undefined>,
      default: () => undefined
    }
  },
  setup () {
    const { setInstance, changeSlide, activeSlideIndex } = useSwiper()
    return {
      swiperDefaultOptions,
      activeSlideIndex,
      setInstance,
      changeSlide
    }
  }
}
