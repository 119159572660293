import { Breakpoint } from './types'

export const DEFAULT_BREAKPOINTS_CONFIG: Record<Breakpoint, number> = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536
}
