
// eslint-disable-next-line n/no-deprecated-api
import { parse } from 'url'
import { defineComponent, computed, PropType, useContext } from '@nuxtjs/composition-api'
import { StrapiSlider } from '@zaprooecommerce/strapi'
import { isExternalLink } from '~/helpers/isExternalLink'
import { getLinkAttributes, BaseLinkAttributes, NuxtLinkAttributes } from '~/helpers/linkAttributes'

export default defineComponent({
  name: 'BannerLink',
  props: {
    banner: {
      type: Object as PropType<StrapiSlider>,
      required: true
    }
  },
  setup (props) {
    const { $config } = useContext()
    const { storeURL } = $config

    const isExternalBannerLink = computed<boolean>(() => isExternalLink(props.banner.url, storeURL))
    const isBaseLinkTag = computed<boolean>(() => isExternalBannerLink.value || props.banner.externalLink)
    const linkComponent = computed<string>(() => isBaseLinkTag.value ? 'a' : 'router-link')
    const link = computed<string>(() => isBaseLinkTag.value ? props.banner.url : parse(props.banner.url).path)

    const attributes = computed<NuxtLinkAttributes | BaseLinkAttributes>(() =>
      getLinkAttributes({
        link: link.value,
        isExternal: isExternalBannerLink.value,
        newTab: props.banner.externalLink
      }))

    return {
      isExternalBannerLink,
      linkComponent,
      attributes
    }
  }
})
