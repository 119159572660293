// @ts-expect-error
import { SwiperOptions } from 'swiper/types'

export const swiperDefaultOptions: SwiperOptions = {
  grabCursor: true,
  slidesPerView: 1,
  speed: 1000,
  loop: true,
  spaceBetween: 16,
  threshold: 2,
  breakpoints: {
    1025: {
      spaceBetween: 30
    }
  },
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  }
}
