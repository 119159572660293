// eslint-disable-next-line n/no-deprecated-api
import { parse } from 'url'
import { isString } from 'lodash-es'

export function isExternalLink (link: string, baseUrl: string): boolean {
  if (!isString(link) || !isString(baseUrl)) {
    throw new Error('Provided arguments should be a type String')
  }

  const { host: hostOfLink } = parse(link)
  if (!hostOfLink) {
    return false
  }

  return hostOfLink !== parse(baseUrl).host
}
