
import { defineComponent, nextTick, onMounted, PropType, ref, computed } from '@nuxtjs/composition-api'
import { StrapiSlider } from '@zaprooecommerce/strapi'
import { useImage } from '~/composables'
import { useViewportObserver } from '~/composables/useViewportObserver'

export default defineComponent({
  name: 'BannerImage',
  props: {
    banner: {
      type: Object as PropType<StrapiSlider>,
      default () {
        return {}
      }
    },
    bannerIndex: {
      type: Number,
      default: null
    },
    eagerLoading: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { getMagentoImage } = useImage()
    const isClient = ref(false)
    const tabletReady = ref(false)
    const mobileReady = ref(false)

    const { isMobile, isTablet, isDesktop } = useViewportObserver()

    const hasOneOtherReady = computed(() => tabletReady.value || mobileReady.value)

    const generalAttributes = {
      fit: 'cover',
      format: 'webp',
      loading: props.eagerLoading ? 'eager' : 'lazy',
      preload: props.eagerLoading ? { fetchPriority: 'high' } : false
    }

    const preparedBanner = computed<StrapiSlider>(() => ({
      ...props.banner,
      tabletImage: props.banner.tabletImage ?? props.banner.desktopImage,
      mobileImage: props.banner.mobileImage ?? props.banner.tabletImage ?? props.banner.desktopImage
    }))

    onMounted(() => {
      nextTick(() => {
        isClient.value = true
      })
    })

    return { getMagentoImage, generalAttributes, preparedBanner, isClient, tabletReady, mobileReady, isMobile, isTablet, isDesktop, hasOneOtherReady }
  }
})
