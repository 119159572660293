import { isString } from 'lodash-es'
import { BaseLinkAttributes, LinkParams, NuxtLinkAttributes } from '~/helpers/linkAttributes/types'

export function getLinkAttributes ({ link = undefined, isExternal = false, newTab = false }: LinkParams): NuxtLinkAttributes | BaseLinkAttributes {
  if (!isString(link)) {
    throw new Error('Provided link should be a type String.')
  }

  return isExternal || newTab
    ? { href: link, target: newTab && '_blank' }
    : { to: link || '' }
}
