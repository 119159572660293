import { debounce } from 'lodash-es'
import { ref, Ref, computed, onUnmounted, onMounted } from '@nuxtjs/composition-api'
import { Breakpoint } from './types'
import { DEFAULT_BREAKPOINTS_CONFIG } from './config'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useViewportObserver (BREAKPOINTS_CONFIG = DEFAULT_BREAKPOINTS_CONFIG) {
  const breakpointsArray: Breakpoint[] = Object.keys(BREAKPOINTS_CONFIG) as Breakpoint[]
  const currentWidth: Ref<number> = ref(!process.server ? window?.innerWidth : 1920)
  const currentHeight: Ref<number> = ref(!process.server ? window?.innerHeight : 1080)
  const current = computed<Breakpoint>(
    () =>
      Object.keys(BREAKPOINTS_CONFIG)
        .reverse()
        .find(key => is(key as Breakpoint)) as Breakpoint
  )

  const isMobile = computed(() => isDown(Breakpoint.md))
  const isTablet = computed(() => isUpper(Breakpoint.sm) && isDown(Breakpoint.lg))
  const isDesktop = computed(() => isUpper(Breakpoint.md))

  const resizeHandler = debounce(() => {
    currentWidth.value = !process.server ? window?.innerWidth : 1920
    currentHeight.value = !process.server ? window?.innerHeight : 1080
  }, 100)

  function is (size: Breakpoint): boolean {
    return currentWidth.value >= BREAKPOINTS_CONFIG[size]
  }

  function isUpper (size: Breakpoint): boolean {
    return is(getNextBreakpoint(size))
  }

  function isDown (size: Breakpoint): boolean {
    return currentWidth.value <= BREAKPOINTS_CONFIG[size] - 1
  }

  function getNextBreakpoint (size: Breakpoint): Breakpoint {
    const sizeIndex: number = breakpointsArray.indexOf(size)
    return breakpointsArray[sizeIndex + 1] ? breakpointsArray[sizeIndex + 1] : breakpointsArray[sizeIndex]
  }

  onMounted(() => {
    window.addEventListener('resize', resizeHandler)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', resizeHandler)
  })

  return {
    currentWidth,
    currentHeight,
    current,
    isMobile,
    isTablet,
    isDesktop,
    is,
    isUpper,
    isDown
  }
}
